import React from "react";
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from "react-bootstrap";
import ColInput from "./ColInput";
import Header from "../../Header/Header";
import stylesSTR from "../../../PortfolioPage/ValueProposition/StrategySystems/Body/BannerStrategySystems.module.css";
import styles from "../../You.module.css";
import ProjectsData from "../Data/ProjectsData";
import { useTranslation } from "react-i18next";

const BannerProject = () => {
  const [tKey, i18n] = useTranslation();
  let { id } = useParams();
  console.log('the id is :'+id);
 let projectInfo = ProjectsData(id);

 // console.log(projectInfo);
  return (
    <section>
      <Container className={""} dir="">
        <Header
          text="Projects"
          classesText={`${stylesSTR.textHeader} ${styles.TitleHeader}`}
          classes={stylesSTR.textHeaderChild}
          nameVideo={"Header.m4v"}
        />
        <Row xl={6} className={` row justify-content-center `}>
          <ColInput
            xl={9}
            classesCol={`${styles.colInput} col-md-9`}
            classInput={`${styles.input} ${styles.inputT}  ${styles.inputImg_1} `}
            placeholder={"Client Name"}
            readOnly
            value={(i18n.language === "en")?projectInfo.client_name_en:projectInfo.client_name_ar}
          />
        </Row>
        <Row xl={6} className={` row justify-content-center `}>
          <ColInput
            xl={9}
            classesCol={`${styles.colInput} col-md-9`}
            classInput={`${styles.input} ${styles.inputT}  ${styles.inputImg_2} `}
            placeholder={"Location"}
            readOnly
            value={(i18n.language === "en")?projectInfo.location:projectInfo.location_ar}
          />
        </Row>
        <Row xl={6} className={` row justify-content-center `}>
          <ColInput
            xl={9}
            classesCol={`${styles.colInput} col-md-9`}
            classInput={`${styles.input} ${styles.inputT} ${styles.inputImg_title} `}
            placeholder={"full Title for project "}
            readOnly
            value={(i18n.language === "en")?projectInfo.title_en:projectInfo.title_ar}
          />
        </Row>
        <Row xl={6} className={` row justify-content-center `}>
          <ColInput
            xl={9}
            classesCol={`${styles.colInput} col-md-9`}
            classInput={`${styles.input} ${styles.inputT}  ${styles.inputImg_3} `}
            placeholder={"Shift required"}
            readOnly
            value={(i18n.language === "en")?projectInfo.shift_required_en:projectInfo.shift_required_ar}
          />
        </Row>
        <Row xl={6} className={` row justify-content-center `}>
          <ColInput
            xl={9}
            classesCol={`${styles.colInput} col-md-9`}
            classInput={`${styles.input} ${styles.inputT}  ${styles.inputImg_4} `}
            placeholder={"Shift acquired"}
            readOnly
            value={(i18n.language === "en")?projectInfo.shift_acquired_en:projectInfo.shift_acquired_ar}
          />
        </Row>
        <Row xl={6} className={` row justify-content-center `}>
          <Col xl={9} className={`col-auto  col-md-9 ${styles.colInput}`}>
            <textarea
              className={styles.textArea}
              rows={11}
              placeholder={"description"}
              readOnly
              value={(i18n.language === "en")?projectInfo.description_en:projectInfo.description_ar}
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BannerProject;
