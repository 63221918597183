import React from "react";
import {  Container } from "react-bootstrap";
import Header from "../../../BlogPage/Header/Header";
import styles from "../../../YouPage/You.module.css";
import RowText from "../../../PortfolioPage/ValueProposition/StrategySystems/Body/RowText";
import stylesSTR from "../../../PortfolioPage/ValueProposition/StrategySystems/Body/BannerStrategySystems.module.css";
import FormPartner from "./FormPartner";
const BannerPartner = () => {
  return (
    <section>
      <Container className={""} dir="">
        <Header
          text="Partner"
          classesText={`${stylesSTR.textHeader} ${styles.TitleHeader}`}
          classes={stylesSTR.textHeaderChild}
          nameVideo={"Header.mp4"}
        />
        <RowText
          classes={styles.textHeader}
          textArray={["textOnePartnerPage"]}
        />
        <FormPartner/>
      </Container>
    </section>
  );
};

export default BannerPartner;
