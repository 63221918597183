import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Header from "../../Header/Header";
import IndividualImage from "./IndividualImage";
import styles from "../../Expertise – Why QUAIM/Body/BannerExpertiseWhyQuaim.module.css";
import "./Tooltip.css";

const BannerExpertiseWhyQuaim = () => {
  const [tKey, i18n] = useTranslation();
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  return (
    <section>
      <Container className={""} dir="">
        <Header text="Why QUAIM?" nameVideo={"Header.mp4"} />
        <Row
          xl={6}
          className={` row justify-content-center ${styles.rowheader}`}
        >
          <Col xl={10} className={`col-auto ${styles.textHeader}`}>
            <p>
              {tKey(
                "The diversified experience, technically in management, quality, and software/information systems engineering, and aligned methodologically in organizational development and transformation."
              )}
            </p>
          </Col>
        </Row>

        <Row
          id={"whyQuaimImg"}
          xl={12}
          className={` row justify-content-center ${styles.bgImg} `}
        >
          <Col className={`col-12 text-center tooltip-container w-100 `}>
            <div className={styles.imageContainer}>
              <IndividualImage
                classImg={styles.img1}
                classtext={styles.text1}
                nameImg={"Why QUAIM Icon_1.png"}
              />
              <IndividualImage
                id={"img2"}
                classImg={styles.img2}
               
                nameImg={"Why QUAIM Icon_2.svg"}
              />
              <IndividualImage
                classImg={styles.img3}
                nameImg={"Why QUAIM Icon_3.png"}
              />
              <IndividualImage
                classImg={styles.img4}
                nameImg={"Why QUAIM Icon_4.png"}
              />
              <IndividualImage
                classImg={styles.img5}
                nameImg={"Why QUAIM Icon_5.png"}
              />
              <IndividualImage
                classImg={styles.img6}
                nameImg={"Why QUAIM Icon_6.png"}
              />

              <IndividualImage
                classImg={styles.img7}
                nameImg={"Why QUAIM Icon_7.png"}
              />
            </div>
            {/*  <span
                        className="tooltip-trigger"
                        onMouseEnter={() => setTooltipVisible(true)}
                        onMouseLeave={() => setTooltipVisible(false)}
                    >
                     <img id={"whyQuaimImg"} alt={"IMG"} className={`${styles.Img}`} src="/assets/img/UsPage/Why QUAIM.png"/>
                    </span>*/}
            {/*  {isTooltipVisible && (
                            <div className="tooltip">
                                Diversified experience
                            </div>
                        )}*/}
          </Col>
        </Row>

        <Row
          xl={6}
          className={` row  justify-content-center   ${styles.rowFotter}`}
        >
          <Col xl={10} className={`col-auto    ${styles.textHeader}`}>
            <p>
              {tKey(
                "The practical Applied experience in leadership management positions in multiple companies and organizations, and consultative roles for many organizations, in many countries"
              )}
            </p>
          </Col>
          <Col xl={10} className={`col-auto mt-1  ${styles.textHeader}`}>
            <p>
              {tKey(
                "Harnessing the globally successful standards and practices in improving non-traditional contexts Application of approaches, channels, and tools that embrace (Customer Centricity)"
              )}
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default BannerExpertiseWhyQuaim;
