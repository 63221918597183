import useAxiosClient from '../Hooks/useAxiosClient';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {SERVER_URL} from "../../config";
const usePostRequest = () => {
const axiosInstance = useAxiosClient();
const toast_style = {
    position: "bottom-center",
            autoClose: 5000,
            width: 250,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
           progressStyle: { background: '#E8DFD0' },
            style: { background: '#2aa7a7' }
            
};
const post = async (url, data) => {
      try {
          const response = await axiosInstance.post(SERVER_URL+url, data);
          toast.success(response.data.message.toUpperCase(),toast_style);
          return response.data;
      } catch (error) {
        toast.error(error.response.data.message.toUpperCase(), toast_style);
      }
  };

  return post;
};
export  default usePostRequest;