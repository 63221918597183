import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Contactus from "./Contactus";
import { validationLan } from "../../ValidationLan/ValidationLan";
import IconLink from "./IconLink";
import IconText from "./IconText";
import HorizontalLine from "./HorizontalLine";
import LinkText from "./LinkText";
import { IconLinkData, IconTextData, LinkTextData } from "./footerData";
import "./Footer.css";

const Footer = (props) => {
  const [tKey, i18n] = useTranslation();
  let isArabic = localStorage.getItem("lan") === "AR";
  useEffect(() => {
    validationLan();
  }, [isArabic]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const linkTextSection = (LinkTextArray) => {
    return LinkTextArray.map((linkText, index) => {
      return <LinkText key={index} link={linkText.link} text={linkText.text} />;
    });
  };

  const iconTextSection = () => {
    return IconTextData.map((iconText, index) => {
      return (
        <IconText
          key={index}
          imgName={iconText.imgName}
          alt={iconText.alt}
          text={iconText.text}
        />
      );
    });
  };

  const iconLinkSection = () => {
    return IconLinkData.map((iconLink, index) => {
      return (
        <IconLink
          key={index}
          link={iconLink.link}
          imgName={iconLink.imgName}
          alt={iconLink.alt}
        />
      );
    });
  };
  return (
    <>
      <Contactus text={"Contact Us Now"} />
      {props.isPorfolio ? <Row className="justify-content-center"></Row> : ''}
    <section className="footer">
        <Container dir={i18n.language === "en" ? "text-left" : "text-right"}>
          
          <Row className="justify-content-between">
            <Col xl={3} md={12} className="col-auto mb-5">
            <img
                  className="logo-footer"
                  src={"/assets/img/QuaimLogo.png"}
                  alt="Logo"
                />
              <HorizontalLine />
              <p className="text-uppercase m-1">
                {tKey(
                  "WE TRANSFORM YOUR PRESENT AND FUTURE TO A SEAMLESS CUSTOMER EXPERIENCE THAT EASILY CONVERTS"
                )}
              </p>
              {iconLinkSection()}
            </Col>

            <Col xl={3} md={12} className="col-auto mb-5">
              <h3 className="text-white mb-2 text-uppercase">
                {tKey("Site map")}
              </h3>
              <HorizontalLine />
              <Row>
                <Col className="col-xl-6 col-md-3">
                  {linkTextSection(LinkTextData.slice(0, 4))}
                </Col>
                <Col className="col-xl-6 col-md-3">
                  {linkTextSection(LinkTextData.slice(4))}
                </Col>
              </Row>
            </Col>
            <Col xl={3} md={12} className="col-auto mb-5">
              <h3 className="text-white mb-2 text-uppercase">
                {tKey("Contact Us")}
              </h3>
              <HorizontalLine />
              {iconTextSection()}
            </Col>
          </Row>
          <Row>
            <hr className="h-line-end" />
            <span className="text-white text-uppercase text-center">
              &copy; 2024 quaim global
            </span>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Footer;
